@use "../variables.scss" as variables;
.sidebarButton {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 0.5rem;
  border-left: 0.25rem transparent solid;
  width: max-content;
  cursor: pointer;

  font-family: "Soleil", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  background-color: transparent;
  outline: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  padding: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.activeItem {
  border-left: 0.25rem var(--primary-color) solid;
  font-weight: 700;
}

.icon {
  margin-left: 0.5rem;
}

.heading {
  margin: 0.75rem;
  word-break: keep-all;

  transition: opacity 0.3s ease-out;
}

.isHidden {
  opacity: 0;
}
