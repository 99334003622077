@import "../../variables.scss";

.multiselect {
  position: relative;
  width: 100%;
}

.dropwdownText {
  @include textFieldStyle;
  cursor: pointer;

  max-width: 100%;
  width: 100%;

  border: none;
  transition: outline-color 0.15s ease-out;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0.5rem;
  min-height: 46px;
  box-sizing: border-box;
  z-index: 1000;
}

.relativeDropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  display: none;
  border-radius: 4px;
  top: 4px;
  box-sizing: border-box;
  padding: 0.75rem;
  outline: 1px solid var(--border-color);
  max-height: 180px;
  overflow: scroll;

  &.visible {
    display: block;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
}

.dropdownRow {
  padding: 0.75rem 1rem;

  border-radius: 8px;
  //border-bottom: 1px solid var(--divider-color);
  border-left: 4px solid transparent;
  cursor: pointer;
  z-index: 600;
  & > * {
    margin: 0;
  }

  &:hover {
    background-color: var(--light-color);
  }

  &:focus {
    outline-color: var(--primary-color);
  }
}

.currentSelection {
  margin: 0;
  padding: 8px 12px;
  font-weight: 700;
}
