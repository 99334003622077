@use "../variables.scss" as variables;
.columnLayout {
  row-gap: 1.5rem;
  column-gap: 1.5rem;
  padding: 1.5rem;
  display: grid;

  grid-template-columns: 1fr;
  min-height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  min-width: 375px;
}

@media (min-width: 768px) {
  .columnLayout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .columnLayout {
    grid-template-columns: repeat(3, 375px);
    padding: 2rem
  }
}

.column {
  display: grid;
  grid-template-columns: 1;
  row-gap: 1.5rem;
  height: min-content;
}
