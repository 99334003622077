@use "../variables.scss" as variables;
.appHeaderBarShortStatistics {
  color: white;
  display: none;
}

@media (min-width: 992px) {
  .appHeaderBarShortStatistics {
    display: flex;
    flex-direction: horizontal;
  }
}

.item {
  display: flex;
  flex-direction: horizontal;
  margin: 0 1.5rem;
}

.item :first-child {
  margin-right: 0.75rem;
}

.item :last-child {
  font-weight: 700;
}
