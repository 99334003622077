@use "../../variables.scss" as variables;
.table {
  width: 100%;
  max-width: 1600px;
  text-align: right;
  border-collapse: collapse;
  padding: 0;
}

.leftColumn {
  text-align: left;
}

.actionButton {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  padding: 0.5rem;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 0.75rem;
  & > img {
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    background-color: var(--light-color);
  }
}

.actionButtonCell {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 0.5rem;
}

.tableRow {
  border-bottom: 2px solid var(--divider-color);

  cursor: pointer;
}

.tableCell {
  padding: 0.75rem 0;
}

.referralTitle {
  font: inherit;
  outline: none;
  border: none;
}
