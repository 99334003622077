@use "../../variables.scss" as variables;
.login {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 100%;
  max-width: 450px;
  background-color: white;
  padding: 2rem;
  margin: 1.5rem;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.75rem;
}
