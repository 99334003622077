@use "../variables.scss" as variables;
.tab {
  background-color: transparent;
  border: none;
  font: inherit;
  height: 3.5rem;
  min-width: 120px;
  cursor: pointer;

  border-bottom: 3px solid transparent;
  &:hover {
    border-color: #0000007f;
  }

  &.selected {
    border-color: black;
  }
}
