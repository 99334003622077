@use "../variables.scss" as variables;
.dataTable {
  width: 320px;
}

.section {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;

  & > :first-child {
    text-align: left;
  }
  & > :last-child {
    text-align: right;
    font-weight: 700;
  }
}
