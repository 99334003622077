@use "../variables.scss" as variables;
.errorInfo {
  display: grid;
  border: 1px solid var(--color-red);
  grid-template-columns: 3.5rem 1fr;
  padding: 1rem 0.75rem;
  border-radius: 4px;
  column-gap: 0.75rem;
}

.text {
  color: var(--color-red);
  margin: 0;
  padding: 0;
}

.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
