@use "../variables.scss" as variables;
.heading {
  color: var(--color-red);
  margin-bottom: 0.75rem;
}

.dangerZone {
  border: 2px solid var(--color-red);
  padding: 1.5rem;
  border-radius: 8px;
}

.dangerZoneRow {
  display: flex;

  .textContainer {
    & > * {
      margin: 0;
    }
  }

  & :last-child {
    justify-self: flex-end;
    margin-left: auto;
  }
}
