@use "../../variables.scss" as variables;
.toggle {
  padding: 0.25rem;
  height: 1rem;
  width: 2.5rem;
  border-radius: 1.5rem;
  display: flex;
  cursor: pointer;
  background-color: #a0a0a0;

  align-items: center;

  &.active {
    background-color: var(--primary-color);
  }
}

.handle {
  background-color: white;

  height: calc(1.5rem - 0.5rem);
  width: calc(1.5rem - 0.5rem);
  border-radius: calc((1.5rem - 0.5rem) / 2);
  justify-self: flex-start;
  transition: justify-self 0.2s ease-in-out;

  &.active {
    justify-self: flex-end;
    margin-left: auto;
  }
}
