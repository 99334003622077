@use "../../variables.scss" as variables;
.saveButton {
  margin-top: 2rem;
  right: 0;
  left: auto;
}

.container {
  width: 500px;
}
