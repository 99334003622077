@use "../variables.scss" as variables;
.loadingSpinner {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.75rem;
}

.dot {
  animation: scale 0.8s infinite;
  background-color: black;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  animation-direction: alternate;
}

@keyframes scale {
  from {
    transform: scale(0.6);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
