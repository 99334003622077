@import "../variables.scss";
.container {
  @include noSelect;

  aspect-ratio: 3.88;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid var(--divider-color);
}

.bannerImage {
  height: 100%;
  width: 100%;
}

.noImage {
  background-color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 3.88;

  & > p {
    font-size: 40px;
    font-weight: 700;
    opacity: 0.35;
    margin: 0;
  }
}
