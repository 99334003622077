@use "../variables.scss" as variables;
.appHeaderBar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: var(--dark-color);
}

.appHeaderBarContainer {
  grid-column-start: 1;
  grid-column-end: 3;
}

.appHeaderBarLeftContainer {
  display: flex;
  align-items: center;
}

.appHeaderBarLeftContainer > img {
  height: 2.5rem;
  width: 2.5rem;
}

.appHeaderBarLeftContainer > button {
  margin-left: 2rem;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
}
