@use "../../variables.scss" as variables;
.iconButton {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;

  height: min-content;
  width: min-content;
}
