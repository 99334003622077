@use "../variables.scss" as variables;
.cellBubble {
  background-color: var(--light-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  text-align: center;
}

.value {
  font-size: 26px;
  font-weight: 700;
  margin: 0 1.5rem;
}

.info {
  font-size: 14px;
  margin: 0 1.5rem;
}
