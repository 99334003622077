@use "../src/variables.scss" as variables;
.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--light-color);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
}

:root {
  --primary-color: #ffda11;
  --dark-color: #1e1e1e;
  --light-color: #f4f4f4;
  --border-color: #e5e5e5;
  --selected-border-color: #b6b6b6;
  --divider-color: #d9d9d9;
  --color-red: #cc0000;

  .primaryButton {
    outline: none;
    border: none;
    background-color: var(--primary-color);
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem 2.5rem;
    border-radius: 8px;
    cursor: pointer;
  }

  .noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .rowContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 6px;
  }

  .bubbleContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 6px;
    column-gap: 6px;
  }

  .tooltip {
    color: white;
    background-color: var(--dark-color);
    margin: 0;
    border-radius: 4px;
    padding: 0.5rem 1rem;
  }

  .inputField {
    outline: none;
    border: none;
    background-color: var(--light-color);
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem 1.5rem;
    border-radius: 8px;
  }
}
