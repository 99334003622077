@use "../variables.scss" as variables;
.distributionBarChart {
  height: 3.5rem;
  width: 100%;
  display: flex;
  display: grid;
  column-gap: 4px;

  p {
    font-size: 15px;
    height: 100%;
    margin: 0;
    text-align: center;
    line-height: 48px;
    color: white;
    font-weight: 700;
    border-radius: 5px;
  }
}
