@use "../../../../variables.scss" as variables;
.campaignCreationDuration {
  margin-top: 1.5rem;
}

.targetFormLayout {
  grid-template-areas:
    "grade grade grade grade grade grade"
    "schoolType schoolType schoolType schoolType schoolType schoolType";
}

.grade {
  grid-area: grade;
}

.schoolType {
  grid-area: schoolType;
}
