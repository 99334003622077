@use "../variables.scss" as variables;
.chartLegend {
  display: flex;
  flex-wrap: wrap;
}

.elementContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  p {
    margin: 0 0.75rem;
  }
  padding: 0.75rem 0.75rem 0.75rem 3px;
}

.colorDot {
  height: 16px;
  width: 16px;
  border-radius: 4px;
}
