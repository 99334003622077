.generalFormLayout {
  grid-template-areas:
    "name name name company company company"
    "targetLink targetLink targetLink cta cta cta";
}

.name {
  grid-area: name;
}

.company {
  grid-area: company;
}

.targetLink {
  grid-area: targetLink;
}

.cta {
  grid-area: cta;
}
