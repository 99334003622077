@import "../../variables.scss";

.multiselect {
  position: relative;
  width: 100%;
}

.textField {
  @include textFieldStyle;
  max-width: 100%;
  width: 100%;

  border: none;
  transition: outline-color 0.15s ease-out;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0.5rem;
  min-height: 46px;
  box-sizing: border-box;
}

.relativeDropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  display: none;
  border-radius: 4px;
  top: 4px;
  box-sizing: border-box;
  padding: 0.75rem;
  outline: 1px solid var(--border-color);
  max-height: 180px;
  overflow: scroll;
  margin-bottom: 2rem;

  &.visible {
    display: block;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
}

.dropdownRow {
  padding: 0.75rem 1rem;

  border-radius: 8px;
  //border-bottom: 1px solid var(--divider-color);
  border-left: 4px solid transparent;
  cursor: pointer;
  z-index: 600;
  & > * {
    margin: 0;
  }
  z-index: 3000;

  &:hover {
    background-color: var(--light-color);
  }

  &.selected {
    // border-left: 4px solid var(--primary-color);
    background-color: rgba(var(--primary-color), 0.3);
  }
}

.multiselectTag {
  display: inline;
  background-color: var(--light-color);
  padding: 8px 14px;
  font-size: 16px;
  height: max-content;
  border-radius: 8px;
  display: flex;
  font-weight: 700;
  column-gap: 0.75rem;
  align-items: center;
  cursor: pointer;

  & > p {
    margin-top: 0;
    margin-bottom: 0;
  }

  & img {
    width: 16px;
    height: 16px;
  }
}

.emptySelection {
  margin: 0;
  padding: 8px 12px;
  opacity: 0.5;
}
