@use "../variables.scss" as variables;
.dashboardStandardCell {
  background-color: white;
  padding: 2rem;
  border-radius: 6px;
}

.heading {
  margin: 0;
  padding: 0;
}

.subheading {
  margin: 0;
  padding: 0;
  opacity: 0.7;
}

.contentContainer {
  margin: -0.5rem;
  margin-top: 1.5rem;
}
