@use "../variables.scss" as variables;
.background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  padding: 2rem;
  border-radius: 8px;
  background-color: white;
  max-width: 400px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.buttonContainer {
  width: max-content;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: 0;
  right: 0;
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 0.75rem;
}
