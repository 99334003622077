@use "../variables.scss" as variables;
.cellRow {
  background-color: var(--light-color);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 1.5rem;
  overflow: visible;
  transition: transform 0.1s ease-in-out;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
}

.cellRow:hover {
  transform: scale(0.99);
}

.cellRow:active {
  transform: scale(0.975);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-size: 1rem;
  font-weight: 300;
  margin: 0px;
  line-height: 1rem;
}

.heading .boldHeading {
  font-weight: 700;
}

.rightElement {
  right: 0px;
  margin-left: auto;
  display: flex;
  justify-content: center;
}
