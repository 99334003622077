@use "../variables.scss" as variables;
.background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.15s ease-out;
  pointer-events: none;

  &.isOpen {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: all;
  }
}

.modal {
  background-color: white;
  max-width: 960px;
  max-height: 80vh;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  visibility: hidden;
  transform: translateY(100vh);
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  display: grid;
  grid-template-rows: max-content 1fr max-content;

  &.isOpen {
    visibility: visible;
    transform: translateY(0px);
  }

  &.navWithBorder {
    .topContent {
      border-color: var(--divider-color);
    }
  }
}

.scrollingContent {
  overflow-y: scroll;
  bottom: 0;
  padding: 0 2.5rem 2.5rem 2.5rem;
}

.topContent {
  height: 100%;
  padding: 0 0 1.5rem 2.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-color: transparent;
  transition: border-color 0.2s ease-out;

  .closeIcon {
    background-color: var(--light-color);
    padding: 0.75rem;
    border-radius: 50%;
    margin: 1.5rem 1.5rem 0 auto;

    width: 48px;
    height: 48px;
    box-sizing: border-box;
  }

  h2 {
    margin: 0;
  }
}

.bottomContent {
  border-top: 2px solid var(--divider-color);
  padding: 1.5rem;

  .buttonContainer {
    margin: 0 0 0 auto;
    max-width: max-content;
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 0.75rem
  }
}
