@use "../variables.scss" as variables;
.barGraph {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}

.datapointRow {
  display: grid;
  grid-template-columns: 1fr 70px;
  grid-template-rows: auto 20px;
  row-gap: 0.5rem;
  column-gap: 1rem;
}

.datapointName {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0px;
}

.bar {
  height: 100%;
  border-radius: 6px;
}

.valueLabel {
  padding: 0px;
  margin: 0px;
  text-align: right;
  line-height: 1;
}
