@use "../../../../variables.scss" as variables;

.campaignCreationDuration {
  margin-top: 1.5rem;
}

.durationFormLayout {
  grid-template-areas:
    "paymentType paymentType paymentType paymentType paymentType paymentType"
    "duration duration duration duration - -";
}

.prepaidFormLayout {
  grid-template-areas:
    "paymentType paymentType paymentType paymentType paymentType paymentType"
    "prepaid prepaid prepaid prepaid - -";
}

.duration {
  grid-area: duration;
}

.prepaid {
  grid-area: prepaid;
}

.paymentType {
  grid-area: paymentType;
}