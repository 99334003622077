@use "../variables.scss" as variables;
.page {
  background-color: white;
  padding: 5rem 3.5rem;
  box-sizing: border-box;
  max-height: 100%;
  height: 100%;
  overflow: scroll;
}

.plusBackground {
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--primary-color);
  border-radius: 1.5rem;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 22px;
    height: 22px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 1rem;
}
