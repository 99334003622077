@use "../variables.scss" as variables;
.pageContent {
  background-color: white;
  padding: 2.5rem 2rem 2.5rem 0;
  display: grid;
  grid-template-areas: "main side";
  grid-template-columns: 960px max-content;
  column-gap: 7rem;
  box-sizing: border-box;
}

.main {
  grid-area: "main";
  max-width: 100%;
  width: 100%;
}

.side {
  grid-area: "side";
  max-width: 100%;
  width: 100%;
}
