body {
  margin: 0;
  font-family: "Soleil", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Soleil";
  src: url("./Assets/Fonts/Soleil-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Soleil";
  src: url("./Assets/Fonts/Soleil-Bold.woff2") format("woff2");
  font-weight: 700;
}

.primaryButton {
  outline: none;
  border: none;
  background-color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  padding: 1.5rem 2.5rem;
  border-radius: 8px;
  cursor: pointer;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.rowContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
}

.bubbleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6px;
  column-gap: 6px;
}

.tooltip {
  color: white;
  background-color: var(--dark-color);
  margin: 0;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.inputField {
  outline: none;
  border: none;
  background-color: var(--light-color);
  font-size: 1rem;
  font-weight: 600;
  padding: 1.5rem 1.5rem;
  border-radius: 8px;
}
