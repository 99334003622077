@use "../../variables.scss" as variables;
.tooltip {
  color: white;
  background-color: var(--dark-color);
  margin: 0;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.tooltipContent {
  cursor: pointer;
}
