@use "../../variables.scss" as variables;
.formHeading {
  margin: 0;
  padding: 0;

  &.XXS {
    margin-bottom: 0.5rem;
  }
  &.XS {
    margin-bottom: 0.75rem;
  }
  &.S {
    margin-bottom: 1rem;
  }
  &.M {
    margin-bottom: 1.5rem;
  }
}
