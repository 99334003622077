@use "../../variables.scss" as variables;
.bannerImage {
  max-width: 100%;
  border-radius: 16px;
}

.section {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
