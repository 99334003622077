@use "../../variables.scss" as variables;
.box {
  background-color: white;
  width: 650px;
  outline: none;
  border-radius: 8px;
  position: relative;
}

.formLayout {
  display: grid;
  grid-template-areas:
    "grade grade grade grade grade grade"
    "schoolType schoolType schoolType schoolType schoolType schoolType";
  row-gap: 1rem;
  column-gap: 1rem;
  padding-bottom: 1.5rem;
  grid-template-columns: repeat(6, 1fr);
}

.priority {
  grid-area: priority;
}

.grade {
  grid-area: grade;
}

.schoolType {
  grid-area: schoolType;
}

.buckedId {
  grid-area: buckedId;
}

.createIdButton {
  grid-area: createIdButton;
}

.createIdButton {
  grid-area: createIdButton;
}

.buttonContainer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  column-gap: 1rem;
}
