@use "../../variables.scss" as variables;
.campaigns {
  background-color: white;
  padding: 5rem 0;
}

.table {
  width: 100%;
  max-width: 1600px;
  text-align: right;
  border-collapse: collapse;
  table-layout: fixed;
}

.campaignColoumn {
  width: 52%;
  text-align: left;
}

.campaignCell {
  text-align: left;

  display: grid;
  align-items: center;
  column-gap: 2rem;

  grid-template-columns: max-content 1fr;
  justify-self: flex-start;
}

.campaignCell p {
  margin: 0;
}

.campaignCell strong {
  margin: 0;
}

.activeIndicator {
  margin: 0 auto;
}

.defaultColoumn {
  width: 12%;
}

.activeColumn {
  max-width: 10px;
  display: block;
}
.tableHeader {
  display: grid;
  grid-template-columns: 50px 5fr 1fr 1fr 1fr 1fr;
}

.tableRow {
  border-bottom: 1.5px solid var(--divider-color);
  padding: 0.25rem 0;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 5fr 1fr 1fr 1fr 1fr;

  &:hover {
    background-color: var(--light-color);
  }
}

.tableRowWrapper {
  display: block;
  width: 100%;
}

.tableCell {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewImage {
  height: 70px;
  border-radius: 8px;
}
