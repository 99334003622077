@use "../variables.scss" as variables;
.appiconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.3s ease-out;
}

.appicon {
  width: 120px;
  height: 120px;
  margin: 80px auto;
  text-align: center;
  border-radius: 22.17%;
  transition: 0.3s ease-out;
}

.collapsed {
  width: 35px;
  height: 35px;
  margin: 2rem auto 3.5rem auto;
}
