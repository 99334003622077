.activeIndicator {
  background-color: var(--border-color);
  height: 1rem;
  width: 1rem;
  display: block;
  border-radius: 0.75rem;

  &.active {
    background-color: var(--primary-color);
    animation: scale 3s ease-in-out infinite;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
}
