@use "../variables.scss" as variables;
.sidebar {
  background-color: white;
  width: 350px;
  overflow: hidden;
  position: relative;
  grid-column: 1;
  grid-row: 2;
  border-right: 1px var(--divider-color) solid;
  transition: 0.3s ease-out;
}

.collapsed {
  width: 0px;
}

@media (min-width: 992px) {
  .collapsed {
    width: 66px;
  }
}

.bottomButtonGroup {
  position: absolute;
  bottom: 2rem;
}
