@use "../../variables.scss" as variables;
.radioItem {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;

  input[type="radio"]:checked,
  input[type="radio"]:not(:checked) {
    margin: 0;
    padding: 0;
  }
}
