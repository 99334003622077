@use "../../variables.scss" as variables;
.button {
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 1.5rem 2.5rem;
  border-radius: 8px;
  cursor: pointer;
  height: min-content;

  transition: 0.1s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  background-color: var(--primary-color);
}

.grey {
  background-color: var(--light-color);
}

.destructive {
  background-color: var(--light-color);
  border: 1px solid var(--divider-color);
  color: var(--color-red);

  &:hover {
    background-color: var(--color-red);
    color: white;
  }
}

.small {
  padding: 1rem 1.5rem;
  font-size: calc(14px);
}
