$PRIMARY_COLOR: #ffda11;
$DARK_COLOR: #1e1e1e;
$LIGHT_GREY: #f4f4f4;
$BORDER_COLOR: #e5e5e5;
$SELECTED_BORDER_COLOR: #b6b6b6;
$DIVIDER_COLOR: #d9d9d9;
$COLOR_RED: #cc0000;

$BOX_XXXS: 0.25rem; //4px
$BOX_XXS: 0.5rem; //8px
$BOX_XS: 0.75rem; //12px
$BOX_S: 1rem; //16px
$BOX_M: 1.5rem; //24px
$BOX_L: 2rem; //32px
$BOX_XL: 2.5rem; //40px
$BOX_XXL: 3.5rem; //56px
$BOX_XXXL: 5rem; //80px

$BORDERRADIUS_S: 4px;
$BORDERRADIUS_M: 8px;
$BORDERRADIUS_L: 16px;

.primaryButton {
  outline: none;
  border: none;
  background-color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  padding: $BOX_M $BOX_XL;
  border-radius: $BORDERRADIUS_M;
  cursor: pointer;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.rowContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
}

.bubbleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6px;
  column-gap: 6px;
}

.tooltip {
  color: white;
  background-color: var(--dark-color);
  margin: 0;
  border-radius: $BORDERRADIUS_S;
  padding: $BOX_XXS $BOX_S;
}

.inputField {
  outline: none;
  border: none;
  background-color: $LIGHT_GREY;
  font-size: 1rem;
  font-weight: 600;
  padding: $BOX_M $BOX_M;
  border-radius: $BORDERRADIUS_M;
}

@mixin textFieldStyle {
  outline: 1px solid $BORDER_COLOR;
  border-radius: 4px;
  background-color: white;

  &:focus {
    outline-color: $SELECTED_BORDER_COLOR;
  }
}

@mixin textFieldErrorStyle {
  outline-color: $COLOR_RED;
  background-color: rgba($COLOR_RED, 0.08);
  &:focus {
    outline-color: $COLOR_RED;
  }
}

@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
