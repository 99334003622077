@use "../../variables.scss" as variables;
.bar {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 1px var(--divider-color) solid;

  .backButton {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 2rem;
    column-gap: 0.75rem;
    background-color: transparent;
    outline: none;
    border: none;
    font: inherit;
    font-weight: 700;
    cursor: pointer;

    & > * {
      margin: 0;
    }
  }

  .buttonContainer {
    align-self: flex-end;
    justify-self: flex-end;
    margin-left: auto;
  }
}
