@import "../../variables.scss";

.textField {
  @include textFieldStyle;
  font: inherit;
  width: 100%;
  box-sizing: border-box;
  border-color: black;
  padding: 1rem;
  border: none;
  font-weight: 700;
  transition: outline-color 0.15s ease-out;
  box-sizing: border-box;

  &::placeholder {
    color: var(--selected-border-color);
    opacity: 1;
  }
}

.label {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
