.container {
  display: grid;

  column-gap: 2px;
  height: 140px;
  width: 100%;
}

.barContainer {
  display: flex;
}

.bar {
  background-color: #27ae60;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
