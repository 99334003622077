@use "../../variables.scss" as variables;
.calloutHeading {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.4;
  margin: 0;
  padding: 0;

  &.XXS {
    margin-bottom: 0.5rem;
  }
  &.XS {
    margin-bottom: 0.75rem;
  }
  &.S {
    margin-bottom: 1rem;
  }
  &.M {
    margin-bottom: 1.5rem;
  }
}
